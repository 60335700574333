#DateTimePicker_Light *{
    border-color:  rgba(0, 0, 0, 0.23) !important
}

#DateTimePicker_Dark *{
    border-color:  rgba(255, 255, 255, 0.23) !important
}

#DateTimePicker_Light label{
    color:  rgba(0, 0, 0, 0.7) !important
}

#DateTimePicker_Dark label{
    color:  rgba(255, 255, 255, 0.7) !important
}
