body, html {
  font-size: 15px;
}

.main-content {
  min-height: calc(100vh - 200px);
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-content: flex-start;
}

::-ms-reveal {
  display: none;
}

@font-face {
  font-family: 'League Script';
  font-weight: 500;
  src: url('./assets/fonts/LeagueScript-Regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'SignPainter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/SignPainter.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-ExtraLight.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-Light.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-Bold.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-SemiBold.ttf') format('truetype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./assets/fonts/NunitoSans-ExtraBold.ttf') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}



.alwaysOnTop {
  z-index: 99999 !important;
}

#ReactSimpleImageViewer {
  z-index: 999999 !important;
  background-color: rgba(0, 0, 0, 0.87) !important;
}

text {
  font-family: 'Nunito Sans';
}

.MuiDataGrid-root {
  --rdg-color: #000;
  --rdg-border-color: rgb(222, 222, 222);
  --rdg-summary-border-color: #aaa;
  --rdg-background-color: rgba(255,255,255,0.2);
  --rdg-header-background-color: rgba(255,255,255,0.2);
  --rdg-row-hover-background-color: rgba(255,255,255,0);
  --rdg-row-selected-background-color: hsl(207deg 76% 92%);
  --rdg-row-selected-hover-background-color: hsl(207deg 76% 88%);
  --rdg-checkbox-color: hsl(207deg 100% 29%);
  --rdg-checkbox-focus-color: hsl(207deg 100% 69%);
  --rdg-checkbox-disabled-border-color: #ccc;
  --rdg-checkbox-disabled-background-color: #ddd;
  --rdg-selection-color: transparent;
  --rdg-font-size: 14px;
  background: 'transparent';
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Nunito Sans","Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.MuiDataGrid-columnHeader--moving {
  background-color: rgba(255,255,255,0) !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 800 !important;
}

.grid {
  border-radius: 10px;
}

/* React File Viewer Style */
.RMM__container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  z-index: 99999 !important;
}

.RMM__body {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 2rem 5% !important;
  z-index: 10001;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Mui DataGrid Style */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

/* Online Hilfe Popover Style */
.reactour__popover {
  border-radius: 10px;
  margin: 10px;
  min-width: 400px;
  padding: 0;
  overflow-x: hidden;
  /* white-space: nowrap;
  text-overflow: ellipsis ellipsis; */

}

.reactour__popover div button {
  padding: 20px !important;
  background: #f3f3f3 !important;
  border-radius: 50%;
}

.horizontalScroll::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;               /* width of vertical scrollbar */
}

.horizontalScroll::-webkit-scrollbar-thumb:horizontal{
  background: #999;
  border-radius: 8px;
}
.verticalScroll::-webkit-scrollbar {
  width: 16px;
}

.verticalScroll::-webkit-scrollbar-track {
  border-radius: 8px;
}

.verticalScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
border: 3px solid transparent;
 -webkit-background-clip: content-box;
 background-clip: content-box;
  background-color: #999;
}

.favoriteAppsBar {
  transition: all 0.1s ease-in-out;
  z-index: 9999;
}

/* .favoriteAppsBar:hover {
  width: 150px
} */

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.goldMemberButton {
  position: relative !important;
  flex-grow: 0 !important;
  transition: all 0.3s ease-in-out !important;
  z-index: 2 !important;
  border-color: #e9c13c !important;
  color: #be9e33 !important;
}

.goldMemberButton span {
  z-index: 3 !important;
}


.dropzone {
  min-height: 170px !important;
  margin-bottom: 10px !important;
  border-width: 2px !important;
  border-color: #32992c !important;
}

.dropzone-text {
  font-size: 14px !important;
  font-weight: 300 !important;
  padding: 8px !important;
}

.slick-next {
  right: 25px !important;
  width: 60px !important;
  height: 60px !important;
}

.slick-prev {
  left: 25px !important;
  z-index: 1 !important;
  width: 60px !important;
  height: 60px !important;
}

.slick-prev:before, .slick-next:before {
  font-size: 40px !important;
}

.lightbox-img img {
  border-radius: 10px;
}