.dropzone-body {
    background: transparent !important;
    min-height: 100px !important;
    border-width: 1px !important;
    margin-bottom: 10px !important;
}

.dropzone-text {
    font-size: 14px !important;
    margin-bottom: 5px !important;
}

.dropzone-body-ticket {
    border: none !important;
    min-height: 30px !important;
    text-align: left !important;
    margin-bottom: 10px !important;
}

.MuiDropzoneArea-textContainer {
    text-align: left !important;
    background-color: transparent !important;
}

.MuiDropzoneArea-root {
    background-color: transparent !important;
}

.MuiDropzonePreviewList-root {
    margin-top: 2px !important;
}

.dropzone-text-ticket {
    display: none !important;
}

.Customer {
    background-color: #FFFFEE !important;
}

.Agent {
    background-color: #E4FFE4 !important;
}
