@font-face {
    font-family: 'Manager';
    font-style: normal;
    src: url('../../../../assets/fonts/SignPainter.ttf') format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }



  :root {
    --video-width: 100%;
    --video-height: 100%;
}
@media (min-aspect-ratio: 4/3) {
    :root { --video-height: 50%; }
}
@media (max-aspect-ratio: 4/3) {
    :root { --video-width: 50%; }
}

.video-background {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100002;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 10/5;
    pointer-events: none;
}

.video-background::before {
  content:"";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: url('../../../../../public/login_bg2.png');
  background-size: cover;
}

.video-background iframe {
    /* position: absolute;
    top: 50%;
    left: 50%; */
    /* width: var(--video-width);
    height: 100%; */
    /* transform: translate(-50%, -50%); */
    z-index: 100002;
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;
}

.login-banner-deprag {
  position: absolute;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 8px;
  background: #fff;
  max-width: 300px;
  bottom: 30px;
  right: -270px;
  transition: all 0.3s ease-in-out 1s;
  animation: .3s ease-in 1s 1 slideInFromRight;
  animation-fill-mode: forwards;
}

@keyframes slideInFromRight {
  0% {
    right: -270px;
  }
  100% {
    right: 30px;
  }
}