.thirdPartyTable, .thirdPartyTable td, .thirdPartyTable th {
    border: 1px solid hsla(0,0%,78%,.87);
    border-collapse: collapse;
}

.thirdPartyTable {
    display: table;       
    width: 100%;
}

.thirdPartyTable thead{    
    background-color: #32992c;
    color: #ffffff;
}

.thirdPartyTable tbody {
    display: table-row-group;
    border-color: inherit;
}

.thirdPartyTable td, .thirdPartyTable th{
    display:table-cell;
    padding: 7px;
    text-align: left;       
}

.thirdPartyTable td:nth-child(2), .thirdPartyTable th:nth-child(2),
.thirdPartyTable td:nth-child(3), .thirdPartyTable th:nth-child(3){
    text-align:center;
}

