.dropzone-body-xls {
    border-width: 1px !important;
    min-height: 30px !important;
    text-align: center !important;
    margin-bottom: 10px !important;
    padding: 10px;
}

.MuiDropzoneArea-textContainer {
    text-align: center !important;
    background-color: transparent !important;
}

.MuiDropzoneArea-root {
    background-color: transparent !important;
}

.MuiDropzonePreviewList-root {
    margin-top: 2px !important;
}

.dropzone-text-xls {
    font-size: 14px !important;
}